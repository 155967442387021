import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as S, useState as _ } from "react";
import { c } from "./chunks/clsx.0d472180.js";
import "./heading.mjs";
import { Text as k } from "./text.mjs";
import { Stack as T } from "./stack.mjs";
import "./grid.mjs";
import "./container.mjs";
import "./sideScrollWrapper.mjs";
import { e } from "./chunks/styles.7e0f5eb5.js";
import { m as j } from "./chunks/motion.24089995.js";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.3bd13943.js";
import "./chunks/utils.60e8c32c.js";
import "./chunks/lodash.14d7cb6d.js";
const v = {
  type: "spring",
  bounce: 0.2,
  duration: 0.6
}, m = S(
  ({
    size: l = "m",
    variant: d = "blue",
    labelHidden: p,
    label: u,
    disabled: i,
    labelPlacement: f = "right",
    checked: r,
    onChange: a,
    defaultChecked: h = !1,
    ...g
  }, y) => {
    const [b, x] = _(h), o = typeof r < "u", s = o ? r : b, N = (n) => {
      a == null || a(n), o || x(n.target.checked);
    };
    return /* @__PURE__ */ t.jsxs(
      T,
      {
        className: c(
          e.root,
          e[d],
          e[l],
          e[f],
          s && e.checked,
          i && e.disabled
        ),
        as: "label",
        type: "horizontal",
        children: [
          /* @__PURE__ */ t.jsx(k, { variant: "s", as: "span", className: c(e.label, p && "visuallyHidden"), children: u }),
          /* @__PURE__ */ t.jsx(
            "input",
            {
              ref: y,
              type: "checkbox",
              ...g,
              checked: s,
              disabled: i,
              className: e.input,
              onChange: N
            }
          ),
          /* @__PURE__ */ t.jsxs("div", { className: e.selectionBox, children: [
            /* @__PURE__ */ t.jsx(j.div, { className: e.knob, layout: !0, transition: v }),
            /* @__PURE__ */ t.jsx("span", { className: e.selectionBackground })
          ] })
        ]
      }
    );
  }
);
try {
  m.displayName = "ToggleSwitch", m.__docgenInfo = { description: "", displayName: "ToggleSwitch", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, variant: { defaultValue: { value: "blue" }, description: "", name: "variant", required: !1, type: { name: "ToggleSwitchVariant" } }, labelHidden: { defaultValue: null, description: "", name: "labelHidden", required: !1, type: { name: "boolean" } }, labelPlacement: { defaultValue: { value: "right" }, description: "", name: "labelPlacement", required: !1, type: { name: "ToggleSwitchLabelPlacement" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "ToggleSwitchSize" } } } };
} catch {
}
export {
  m as ToggleSwitch
};
